/* You can add global styles to this file, and also import other style files */
@import '~bootstrap/dist/css/bootstrap.min.css';

/*
Default Style
============================*/

:root {
    --bodyFontFamily: 'IBM Plex Sans', sans-serif;
    --headingFontFamily: 'IBM Plex Sans', sans-serif;
    --mainColor: #1d42d9;
    --bodyColor: #7a7e9a;
    --headingColor: #293a5c;
    --whiteColor: #ffffff;
    --blackColor: #000000;
    --darkBlueColor: #051242;
    --boxShadow: 0 0 20px 3px rgba(0, 0, 0, 0.05);
    --transition: all 0.5s;
    --borderRadius: 4px;
}

body {
    padding: 0;
    margin: 0;
    font-size: 18px;
    line-height: 1.8;
    font-family: var(--bodyFontFamily);
    color: var(--bodyColor);
}
a {
    display: inline-block;
    transition: var(--transition);
    text-decoration: none;
    &:hover {
        text-decoration: none;
        color: var(--headingColor);
    }
    &:focus {
        text-decoration: none;
    }
}
button {
    outline: 0 !important;
    box-shadow: none;
    border: none;
    &:focus {
        box-shadow: none;
    }
}
.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
    color: var(--headingColor);
    font-family: var(--headingFontFamily);
    font-weight: 600;
}
h3 {
    font-size: 21px;
    line-height: 1.5;
}
h1{
    font-size: 48px;
}
.d-table {
    width: 100%;
    height: 100%;
}
.d-table-cell {
    vertical-align: middle;
}
p {
    font-size: 18px;
    margin-bottom: 15px;
    font-weight: 300;
    &:last-child {
        margin-bottom: 0;
    }
}
img {
    max-width: 100%;
    height: auto;
}
ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

textarea {
    &.form-control {
        height: auto;
        height: 250px;
    }
}
.ptb-100 {
    padding-top: 100px;
    padding-bottom: 100px;
}
.pt-100 {
    padding-top: 100px;
}
.pb-100 {
    padding-bottom: 100px;
}
.pt-70 {
    padding-top: 70px;
}
.pb-70 {
    padding-bottom: 70px;
}
.mb-30 {
    margin-bottom: 30px;
}
.pl-30 {
    padding-left: 30px;
}
.pr-30 {
    padding-right: 30px;
}
.mt-30 {
    margin-top: 30px;
}
.mt-20 {
    margin-top: 20px;
}
.mb-20 {
    margin-bottom: 20px;
}
.mt-minus-70 {
    margin-top: -70px;
}
.mb-minus-70 {
    margin-bottom: -70px;
}
.ml-30 {
    margin-left: 30px;
}
.mr-30 {
    margin-right: 30px;
}
.bg-color {
    background: #181818;
}

.navbar-logo {
    width: 65%;
}

/*
Go Top Style*/
.go-top {
    position: fixed;
    cursor: pointer;
    top: 93%;
    right: -10%;
    background-color: var(--mainColor);
    z-index: 4;
    width: 40px;
    text-align: center;
    height: 42px;
    line-height: 42px;
    opacity: 0;
    visibility: hidden;
    transition: 0.9s;
    i {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 0;
        right: 0;
        margin: 0 auto;
        color: var(--whiteColor);
        transition: 0.5s;
        font-size: 20px;
        &:last-child {
            opacity: 0;
            visibility: hidden;
            top: 60%;
        }
    }
    &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
        background-color: var(--mainColor);
        opacity: 0;
        visibility: hidden;
        transition: 0.5s;
    }
    &:hover {
        color: var(--whiteColor);
        background-color: var(--headingColor);
        &::before {
            opacity: 1;
            visibility: visible;
        }
        i {
            &:first-child {
                opacity: 0;
                top: 0;
                visibility: hidden;
            }
            &:last-child {
                opacity: 1;
                visibility: visible;
                top: 50%;
            }
        }
    }
    &:focus {
        color: var(--whiteColor);
        &::before {
            opacity: 1;
            visibility: visible;
        }
        i {
            &:first-child {
                opacity: 0;
                top: 0;
                visibility: hidden;
            }
            &:last-child {
                opacity: 1;
                visibility: visible;
                top: 50%;
            }
        }
    }
}
.go-top.active {
    transform: translateY(0);
    opacity: 1;
    visibility: visible;
    right: 3%;
    top: 93%;
}

/** Section tittle Css **/
.section-title {
    position: relative;
    max-width: 695px;
    margin: -8px auto 60px;
    text-align: center;
    span {
        color: var(--mainColor);
        font-size: 14px;
        position: relative;
        display: inline-block;
        margin-bottom: 8px;
    }
    h2 {
        font-size: 40px;
        text-transform: capitalize;
        color: var(--headingColor);
        margin-bottom: 20px;
    }
}

/** Box Btn Css **/
.box-btn {
    font-size: 18px;
    font-weight: 600;
    color: var(--whiteColor);
    padding: 15px 25px;
    line-height: 1;
    transition: var(--transition);
    text-transform: capitalize;
    position: relative;
    cursor: pointer;
    border-radius: 4px;
    text-align: center;
    overflow: hidden;
    z-index: 1;
    background-color:#0366DD ;
    border: 1px solid var(--mainColor);
    &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 100%;
        width: 100%;
        height: 50%;
        background-color: var(--darkBlueColor);
        z-index: -1;
        transition: all 0.5s;
    }
    &::after {
        content: "";
        position: absolute;
        bottom: 0;
        right: 100%;
        width: 100%;
        height: 50%;
        background-color: var(--darkBlueColor);
        z-index: -1;
        transition: all 0.5s;
    }
    &.border-btn {
        background-color: transparent;
        border: 1px solid var(--whiteColor);
    }
    &:hover {
        color: var(--whiteColor);
        &::before {
            left: 0;
        }
        &::after {
            right: 0;
        }
    }
}
/*
Navbar Area Css
======================================================*/
.navbar-area {
   
    transition: 0.3s;
    padding: 0;

    &.three {
        .main-nav {
            background-color: var(--darkBlueColor);
            position: realtive !important;
        }
    }
    .main-nav {
        background: #132460;
        position: absolute !important;
        top: 0px !important;
        left: 0;
        padding-top: 15px;
        padding-bottom: 15px;
        width: 100%;
        z-index: 999;
        height: auto;
        margin-bottom:3%;

        &.sticky-box-shadow {
            position: fixed !important;
            top: 0 !important;
            width: 100%;
            left: 0;
            z-index: 999;
            animation: fadeInDown 500ms ease-in-out;
            transition: 0.3s;
            box-shadow: 0 1px 10px rgba(221, 221, 221, 0.2);
            background: var(--darkBlueColor) !important;
        }
    }
    .main-nav {
        background: #041342;
        transition: 0.4s;

        .navbar {
            transition: 0.4s;
            padding: {
                right: 0;
                top: 0;
                left: 0;
                bottom: 0;
            }

            ul {
                padding-left: 0;
                list-style-type: none;
                margin-bottom: 0;
            }
            .navbar-collapse {
                display: flex;
                justify-content: flex-end;
                flex-grow: 1;
            }
            .navbar-nav {
                margin: {
                    display: flex;
                    align-items: right;
                }
                .nav-item {
                    position: relative;
                    padding: {
                        top: 15px;
                        bottom: 15px;
                        left: 15px;
                        right: 15px;
                    }

                    a {
                        font: {
                            size: 20px;
                            weight: 700;
                            
                        }
                        color: var(--whiteColor);
                        text-transform: capitalize;
                        padding: {
                            left: 0;
                            right: 0;
                            top: 0;
                            bottom: 0;
                        }
                        margin: {
                            left: 0;
                            right: 0;
                        }
                        &:hover,
                        &:focus,
                        &.active {
                            color: #70a1ff;
                        }
                        i {
                            font-size: 16px;
                            position: relative;
                            top: 2px;
                            margin-left: 1px;
                        }
                    }
                    &:hover,
                    &.active {
                        a {
                            color: #70a1ff;
                        }
                    }
                    .dropdown-menu {
                        box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
                        background: var(--whiteColor);
                        position: absolute;
                        border: none;
                        top: 80px;
                        left: 0;
                        width: 240px;
                        z-index: 99;
                        display: block;
                        opacity: 0;
                        visibility: hidden;
                        border-radius: 0;
                        transition: all 0.3s ease-in-out;
                        &::before {
                            position: absolute;
                            width: 0;
                            height: 0;
                            content: "";
                            border-top: 0;
                            border-right: 8px solid transparent;
                            border-bottom: 8px solid var(--whiteColor);
                            border-left: 8px solid transparent;
                            top: -8px;
                            left: 20px;
                            z-index: -11;
                        }
                        padding: {
                            top: 0;
                            left: 0px;
                            right: 0px;
                            bottom: 0;
                        }
                        li {
                            padding: 0;
                            position: relative;
                            overflow: hidden;
                            &::before {
                                position: absolute;
                                content: "";
                                top: 0;
                                left: 0px;
                                width: 0;
                                height: 100px;
                                background: var(--mainColor);
                                transition: 0.5s all ease;
                                z-index: -1;
                            }
                            a {
                                text-transform: capitalize;
                                padding: 10px 25px;
                                margin: 0;
                                color: #181818;
                                border-bottom: 1px dashed #eeeeee;
                                font: {
                                    size: 15px;
                                }
                                &:hover,
                                &:focus,
                                &.active {
                                    color: var(--mainColor);
                                }
                            }
                            &.active {
                                a {
                                    color: var(--mainColor);
                                }
                            }
                            &:hover {
                                a {
                                    color: var(--whiteColor);
                                }
                                .dropdown-menu {
                                    opacity: 1;
                                    visibility: visible;
                                    top: -15px;
                                }
                                &::before {
                                    width: 100%;
                                }
                            }
                            &:last-child {
                                a {
                                    border-bottom: none;
                                }
                            }
                        }
                    }
                    .dropdown-toggle {
                        &::after {
                            content: "+";
                            margin-left: 0.355em;
                            vertical-align: 0;
                            border-top: none;
                            border-right: none;
                            border-bottom: 0;
                            border-left: none;
                            transition: 0.3s all;
                        }
                    }
                    &:hover {
                        .dropdown-menu {
                            opacity: 1;
                            visibility: visible;
                            top: 50px;
                        }
                    }
                }
            }
        }
    }
    .box-btn {
        &::before,
        &::after {
            background: var(--whiteColor);
        }
        &:hover {
            color: #132460;
        }
    }
    .nav-btn {
        margin-left: 20px;
        position: relative;
        top: 3px;
    }
    .nav-right {
        display: inline-flex;
        width: 100%;
        position: relative;
        top: -3px;
        ::placeholder {
            color: #f5faf5;
            opacity: 1;
        }
        max-width: 16%;
        input.search {
            background: transparent;
            border: none;
            border-bottom: 1px solid #f5faff;
            position: relative;
            color: #f5faff;
            padding-left: 0;
            border-radius: 0;
            &:focus {
                box-shadow: none;
            }
        }
        button {
            background: none;
            border: none;
            color: #f5faff;
            font-size: 20px;
            position: absolute;
            top: 12px;
            right: 0;
            cursor: pointer;
            transition: var(--transition);
            z-index: 1;
            &:hover {
                color: var(--mainColor);
            }
        }
    }
}
@media (max-width: 991.98px) {
    .navbar-toggler {
        display: block;
    }

    .navbar-collapse.mean-menu {
        display: none;
    }

    .navbar-collapse.mean-menu.open {
        display: block;
    }
}

@media (min-width: 992px) {
    .navbar-toggler {
        display: none;
    }
}

/*
Banner Area Css
======================================================*/
.banner-area {
    position: relative;
    background: url(assets/images/home-bg-2.png);
    background-size: cover;
    overflow: hidden;
    width: 100%;
    padding-bottom: 5%;
    padding-top: 5%;

    .banner-content {
        max-width: 650px;
        margin-left: auto;
        h1 {
            color: var(--whiteColor);
            margin: 0 0 30px;
            text-align: left;

        }
        p {
            color: var(--whiteColor);
            padding-right: 0%;
            letter-spacing: 1px;
            word-spacing: 1px;
        }
        .banner-btn {
            margin-top: 45px;
            .box-btn {
                margin-right: 20px;
                &::before,
                &::after {
                    background: var(--whiteColor);
                }
                &:hover {
                    color: #132460;
                }
            }
        }
    }
   
    
}
/*ABOUT US */

.aboutus {
    position: relative;
    background: url(assets/images/feature.png);
    background-size: cover;
    overflow: hidden;
    width: 100%;
    height: auto;
    padding-top:7%;
    padding-bottom: 7%;
}
.aboutus-content {
    margin-right: 40%;

    h1 {
        color: var(--blackColor);
        margin: 0 0 20px;
    }
    p {

        color: var(--blackColor);
        text-align: justify;
    }
}
.aboutus-img {
    margin-left: 40%;
    width: 50%;
}

/*
Services
======================================================*/
.services {
    position: relative;
    background: url(assets/images/home-bg-2.png);
    background-size: cover;
    overflow: hidden;
    width: 100%;
    height: 100%;
    padding-bottom: 5%;
    .list-group-item-img {
        height: 120px; /* You can adjust this value according to your design */
        overflow: hidden;
    }
    .list-group-item-img img {
        height: 100%;
        width: 100%;
        object-fit:contain;
        object-position: center;
    }
   
   
    .title {
        color: var(--whiteColor);
        
        text-align: center;
    }
    p {
        color: var(--blackColor);
        text-align: center;
        padding: {
            top: 5%;
            bottom: 2%;
            left:2%;
            right: 2%;
        }
        white-space: pre-line;
        font-weight: 300;
   
    }
    
    h1 {
        color: var(--whiteColor);
        color: var(--whiteColor);
        text-align: center;
        width: 100%;
        margin-top: 5%;
    }

    h3 {
        color: var(--blackColor);
        text-align: center;
        padding-top: 2%;
    }

    .service-icons {
        width: 33%;
        height: auto;
        display: flex;
        justify-content: center;
        align-items: center;
        padding-top: 5%;
    }
    .service-card {
        display: flex;
        flex-direction: column;
        justify-content:center;
        overflow: hidden; /* Hide any content that overflows the card height */
        margin-right: 10%;
        padding: 2%;
        border: none !important;
        margin-bottom: 2%;
        height: 100%;
        
    }

.service-card .list-group-item-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.service-card .list-group-item-content .box-btn.s-b {
    align-self: center;
    margin-top: 1rem;
    
}


}
.list-group-item-img {
    padding-top: 5%;
}
.list-group-item-content {
    width: auto;
}

/*
Error Area Css
======================================================*/
.error-area {
    position: relative;
    text-align: center;

    h3 {
        color: #e6335a;
        margin-top: 30px;
        font-size: 30px;
    }
}

/* Contact Area Css
====================================================== */
.contact-area {
    padding-top: 10%;
    position: relative;
    background: url(assets/images/feature.png);
    background-size: cover;
    overflow: hidden;
    width: 100%;
    height: 100%;
    padding: 2%;
}
.contact-us-title{
text-align: left;
        color: var(--blackColor);
        margin: 0 0 20px;
}

/*
Footer Area Css
======================================================*/
.social-icon {
    font-size: 2em;
    color: var(--whiteColor);
    width: 32px; // adjust as needed
    margin: 0.2rem;
}

h2 {
    font-weight: 400;
    font-size: 2em;
    font-weight: bold;
    color: var(--whiteColor);
}

.emailtext {
    margin-right: 2rem !important;
    font-family: "Roboto Flex", sans-serif;
    font-weight: 400;
}

p {
  
    font-weight: 300;
    color: var(--whiteColor);
}

.address {
    margin-left: 15%;
}

.connectus {
    margin-right: 55%;
}
// Style for hoovered icons

.navbar-tex {
    font-family: "Roboto Flex", sans-serif;
    font-weight: 400;
}

// Define the animationFoot
.animate-robot {
    animation: robot-appear 0.8s ease-in-out both;
}

footer {
    background-color: transparent !important;
}

@keyframes robot-appear {
    0% {
        opacity: 0;
        transform: translateY(20px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

.footer-area {
    position: relative;
    overflow: hidden;
    background: url(assets/images/footer-bg.png) no-repeat;
    background-size: cover;
    background-position: center;
    z-index: 1;

    &::before {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
        background-image: url(assets/images/footer-bg1.png);
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        animation: imageChange 1s linear 1s ;

        @keyframes imageChange {
            0% {
                opacity: 1;
            }
            75% {
                opacity: 0;
            }
            100% {
                opacity: 1;
            }
        }
    }
}

  .carousel-control-prev-icon,
.carousel-control-next-icon {
  background-color: black;
  height: 35px;
  width: 35px;
  filter: invert(1); /* Add this line to make the arrows black */
}

.carousel-control-prev,
.carousel-control-next {
  width: auto;
}
.carousel-indicators li {
    background-color: black;
  }
  

.feature{
    background-color:white;
    padding: 5%;
  
    h2{
        color: var(--blackColor);
        padding-bottom: 2%;
    }
    p{
        color: var(--blackColor);
        text-align: justify;
        
    }
}
.caoursel{
    background: url(assets/images/home-bg-2.png);
    background-size: cover;
   
    padding: 5%;
}
.custom-container {
    max-width: 70%; /* Adjust the percentage to set the maximum width */
  }

  .icon-services{
    padding-right:1%;
   
  }

  // Form container
.form-container {
    width: 80%;
    margin: 0;
    display: flex;
    flex-direction: column;
    align-items: left;
  }
  
  // Form title
.form-title {
    display: flex;
    align-items: center; // Add this line to vertically align the title with the logo
  }
  
  // Logo
  .logo {
    width: 30%;
    height: auto;
    margin-right: 1%;
    margin-left:-3%;// Add some margin to the right of the logo
  }
  
  // Form labels
  label {
    display: block;
    font-weight:600;
    margin-top: 5px;
    color:var(--blackColor);
    
  }
  // Form textarea element
textarea {
    width: 100%;
    padding: 10px;
    font-size: 14px;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-bottom: 15px;
    resize: vertical; // Allow vertical resizing of the textarea
  }
  
  
  // Form input and select elements
  input,
  select {
    width: 100%;
    padding: 10px;
    font-size: 14px;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-bottom: 15px;
  }
  
  // Form submit button

  .success-message{
    color: #000000;
    text-align: center;
  }
  