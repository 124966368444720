 // Width 1398
@media screen and (max-width: 1398px) {
    .banner-area {
       
        .banner-content {
            margin-top: 15%;
            margin-left:8%;
            text-align: left;
            text-align:justify ;
            max-width: unset;
            h1 {
                font-size: 40px;
                margin-bottom: 15px;
            }
            p {
                margin-bottom: 0;
            }
            .banner-btn {
                margin-top: 25px;
                .box-btn {
                    margin-right: 0;
                    margin: 0 8px;
                }
            }
        }
        .banner-img {
            margin-top: 100px;
        }
        .home-shape{
            display:none;
        }
    }
    .services {
        display: flex;
        flex-direction: column;
        align-items: center;
      }
      
      .services h3 {
        text-align: center;
      }
      
      .services .row {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        
      }
      
      .services .card {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 78%;
      }
      
      .services .card .list-group-item-img {
        height: 17.5%;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      
      .services .card .list-group-item-img img {
        max-height: 80%;
      }
      
      .services .card p {
        text-align: center;
       
      }
      
     
}


// max width 990px
@media screen and (max-width: 990px) {
    .services {
        .service-card {
            height: 90%;
            width: 105%;
        }
    }
    .home-shape{
        display: none;
    }
    .aboutus {
        .img {
            margin-bottom: 5%;
        }
        .aboutus-content {
            text-align: center;
            margin-left: 30%;
            p {
                text-align: left;
                text-size-adjust: 100%;
                width: 150% !important;
                margin-left: -30% !important;
                text-align: justify;
                margin-right: 35% !important;
            }
        }
    }
    .services {
        .service-card {
            height: 65%;
        }
        .service-icons {
            width: 50%;
        }
        .col-md-6 {
            width:78%!important;
        }
    }
    .contact-area {
        .maps-content {
            height: 10%;
        }
    }
}
/* Max width 1800px */
@media only screen and (max-width: 1800px) {
    .banner-area {
        .home-shape{
            display: none;
        }
    }
    .banner-content {
        margin-top: 15%;
        margin-left:8%;
        text-align: left;
        text-align:justify ;
        max-width: unset;
    }
    .banner-img {
        margin-top: 100px;
    }
    
}
/* Max width 1800px */
@media only screen and (min-width: 1801px) {
    .banner-area {
        .home-shape{
            display: none;
        }
    }
    .banner-content {
        margin-top: 15%;
        margin-left:8%;
        text-align: left;
        text-align:justify ;
        max-width: unset;
    }
    .banner-img {
        margin-top: 100px;
    }
    
}
/* Max width 991px Only Mobile Devices */
@media only screen and (max-width: 991px) {
    .navbar-area {
        border-bottom: 1px solid #242424;

        .main-nav {
            position: relative ;
            top: 0 !important;
            background-color: var(--darkBlueColor);

            .navbar {
    

                .collapse {
                    margin-top: 15px;
                    max-height: 70vh;
                    overflow-y: scroll;
                }
                .navbar-nav {
                    .nav-item {
                        .dropdown-menu {
                            position: relative;
                            top: 0;
                            width: 100%;
                            opacity: 1;
                            visibility: visible;
                            margin-top: 18px;
                        }
                    }
                }
            }
        }
        .navbar-brand {
            &.d-none {
                display: inline-block !important;
            }
        }
        .nav-right {
            display: none;
        }
        .nav-btn {
            display: none;
        }
        .navbar-toggler {
            color: var(--whiteColor);
            font-size: inherit;
            box-shadow: unset;
            border: none;
            padding: 0;

            .burger-menu {
                cursor: pointer;

                span {
                    height: 2px;
                    width: 30px;
                    margin: 6px 0;
                    display: block;
                    background: var(--whiteColor);
                }
            }
        }
        .active {
            .navbar-toggler {
                .burger-menu {
                    span {
                        &.top-bar {
                            transform: rotate(45deg);
                            transform-origin: 10% 10%;
                        }
                        &.middle-bar {
                            opacity: 0;
                        }
                        &.bottom-bar {
                            transform: rotate(-45deg);
                            transform-origin: 10% 90%;
                            margin-top: 5px;
                        }
                    }
                }
            }
            .collapse:not(.show) {
                display: block;
            }
        }
    }
}


/* Min width 768px to Max width 991px */
@media (min-width: 769px) and (max-width: 1024px) {
    .ptb-100 {
        padding-top: 50px;
        padding-bottom: 50px;
    }
    .pt-100 {
        padding-top: 50px;
    }
    .pb-100 {
        padding-bottom: 50px;
    }
    .pt-70 {
        padding-top: 20px;
    }
    .pb-70 {
        padding-bottom: 20px;
    }
    .pb-130 {
        padding-bottom: 40px;
    }
    .maps-content{
        display: none;
    }
    .banner-area {
        .container-fluid {
            max-width: 720px;
        }
        .banner-content {
            margin-top: 0;
            text-align: center;
            max-width: unset;
            h1 {
                font-size: 40px;
                margin-bottom: 15px;
            }
            p {
                margin-bottom: 0;
            }
            .banner-btn {
                margin-top: 25px;
                .box-btn {
                    margin-right: 0;
                    margin: 0 8px;
                }
            }
        }
        .banner-img {
            margin-top: 30px;
        }
    }
    .box-btn {
        padding: 10px 10px;
    }
    .banner-area {
        padding-top: 130px;
        padding-bottom: 70px;
        height: 100%;
    }
    .section-title {
        margin-bottom: 30px;
        h2 {
            font-size: 30px;
            margin-bottom: 10px;
        }
    }

}
    .navbar-brand{
        margin: 0;

    }
    //About  Us
    
    
//Footer Area

/* Max width 768px */
@media (max-width: 767px) {
    .ptb-100 {
        padding-top: 50px;
        padding-bottom: 50px;
    }
    .pt-100 {
        padding-top: 50px;
    }
    .pb-100 {
        padding-bottom: 50px;
    }
    .pt-70 {
        padding-top: 20px;
    }
    .pb-70 {
        padding-bottom: 20px;
    }
    .pb-130 {
        padding-bottom: 40px;
    }
    h1{
        font-size: 40px;
    }
    h3 {
        font-size: 18px;
    }
    h2 {
        font-size: 22px;
    }
    
    .banner-area {
        .container-fluid {
            max-width: 540px;
        }
        .banner-content {
            margin-top: 1;
            text-align: center;
            max-width: unset;
            h1 {
                font-size: 35px;
                margin-bottom: 15px;
            }
            p {
                margin-bottom: 0;
            }
            .banner-btn {
                margin-top: 25px;
                .box-btn {
                    margin-right: 0;
                    margin: 0 8px;
                }
            }
        }
        .banner-img {
            margin-top: 15px;
        }
    }
    .box-btn {
        padding: 10px 10px;
    }
    p {
        font-size: 14px;
    }
    .banner-area {
        padding-top: 110px;
        padding-bottom: 50px;
        height: 100%;
    }
    .section-title {
        margin-bottom: 30px;
        h2 {
            font-size: 25px;
            margin-bottom: 10px;
        }
    }
    .maps-content{
        display: none;
    }
    .header-area {
        .header-content-right {
            float: unset;
        }
        .col-lg-8.col-sm-8.text-end.pr-0 {
            text-align: center !important;
            padding-right: 15px !important;
        }
        .col-lg-2.col-sm-4.text-end.pl-0 {
            text-align: center !important;
        }
    }
    .services {
        .services .row {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            margin-top: 1rem;
            margin-left: -0.5rem; /* add a negative margin */
            margin-right: -0.5rem; /* add a negative margin */
          }
          
          .col-md-6 {
            width:90%!important;
            height: 80%;
        }
        .list-group-item-img img {
            height: 50%;
            width: 35%;
            object-fit: contain;
            object-position: center;
    }
    .home-shape{
        display:none;
    }

}
.icon-services{
    display: none;
}
.caoursel {
    padding-left: 0;
    padding-right: 0;
    h1{
        font-size: 30px;
        text-align: center;
    }
    .service-btn{
        padding-bottom: 10%;
    }

}
  .connectus {
    display: none;
    
  }

  .emailtext {
    display: none;
  }

  .copy {
    text-align: center;
  }
  .address{
    margin-left:40% !important;
  }
  .aboutus{
    .img{
        display: none;
    }
   
  }
  //Footer
.footer-area{
    align-content: center;
}
}
